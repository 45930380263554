import { post, get } from './request'

export default {
    /**
     * 添加设备
     * @returns {Promise | Promise<unknown>}
     */
    bindEquip(params) {
        return post('/entity/createFence', params)
    },
    /**
     * 移除设备
     * @param id
     * @returns {Promise | Promise<unknown>}
     */
    removeBind(param) {
        return post('/entity/unBindingLocalEntity', param)
    },

    /**
     * 获取围栏绑定设备列表
     * @param id
     * @returns {Promise | Promise<unknown>}
     */
    list(param) {
        return get('/entity/findEntityByFenceId', param)
    },

    /**
     * 监控中设备列表
     * @param param
     * @returns {Promise | Promise<unknown>}
     */
    getListInMonitor(param) {
        return post('/entity/findEntityPage?pageNum='
            + param.pageNum + '&pageSize=' + param.pageSize + '&entityNumber='
            + param.equipSn + '&entityFactory=' + param.factoryName)
    },
    /**
     * 搜索车牌号
     * @param param
     */
    searchCarNo(key) {
        if (key == null) return;
        return get('/localLoc/searchDevice/' + key)
    },
    /**
     * 条件搜索
     * @param param
     * @returns {Promise | Promise<unknown>}
     */
    getListInMysql(param) {
        return post('/entity/findyListEntity', param)
    },
    /**
     * 导出设备列表
     * @param param
     * @returns {Promise | Promise<unknown>}
     */
    Excelentity(param) {
        return post('/entity/exportEntityList', param)
    },
    /**
 * 批量上线/下线
 * @param param
 * @returns {Promise | Promise<unknown>}
 */
    BatchEditStatus(param) {
        return get('/entity/batchEditStatus', param)
    },

    /**
* 删除设备
* @param param
* @returns {Promise | Promise<unknown>}
*/
    BatchDelEntity(param) {
        return get('/entity/batchDelEntity', param)
    },
}
